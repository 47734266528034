import axios from "./axios";
export default class ResponseService {
  static getResponses(params) {
    return axios.get("Responses", { params }).then((response) => response.data);
  }

  static getRecentObservations() {
    return axios.get("Responses", { params: { sortField: "createddate", sortDirection: "desc", pageSize: 8 } }).then((response) => response.data);
  }

  static getResponse(id) {
    return axios.get(`Responses/${id}`).then((response) => response.data);
  }
  static deleteResponse(id){
    return axios.delete(`Responses/${id}`).then((response) => response.data);
  }

  static excelExport(searchFields) {
    return axios.post("Responses/ExcelExport", searchFields, { responseType: "blob" }).then(({ data }) => data);
  }
}
