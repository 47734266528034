import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import logo from "../resources/logo.svg";
import { Image } from "primereact/image";
import { useNavigate, useLocation } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import AuthenticationService from "../services/AuthenticationService";
import { Divider } from 'primereact/divider';
import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { getSelf } from "../util/cache";
import "./main-layout.css";

export default function Layout({ breadcrumbs, title, children }) {
  const menu = useRef(null);
  const redirect = useNavigate();
  const location = useLocation();
  const items = [
    {
      label: "Home",
      icon: "pi pi-fw pi-home",
      command: () => redirect("/"),
      className: location.pathname === "/" ?"p-menuitem-active" : "",
    },
    {
      label: "Saved Observations",
      icon: "pi pi-fw pi-chart-line",
      command: () => redirect("/observations"),
      className: location.pathname === "/observations" ?"p-menuitem-active" : "",
    },
  ];
  const user = getSelf();
  const menuItems = [
    {
      label: `${user.firstName} ${user.lastName}`,
      items: [
        {
          label: "Account",
          icon: "pi pi-user",
          command: () => redirect("/user-info"),
        },
        {
          label: "Update Password",
          icon: "pi pi-key",
          command: () => redirect("/change-password"),
        },
        {
          label: "Logout",
          icon: "pi pi-power-off",
          command: () => onLogout(),
        },
      ],
    },
  ];

  const start = (
    <div className="flex align-items-center">
      <Image height={50} src={logo} />
      <label className="ml-1 font-bold">OPQ Tool</label>
    </div>
  );

  const onLogout = () => {
    AuthenticationService.logout().then(() => redirect("/login"));
  };

  return (
    <div className="min-h-screen surface-500 mt-0 flex flex-column">
      <Menubar className="p-button-rounded px-4 py-3 lg:py-0 bg-white" model={items} start={start} end={<Button className="round-avatar-btn" label={`${user.firstName[0]}${user.lastName[0]}`} onClick={(evt) => menu.current.toggle(evt)} />} />
      <Menu model={menuItems} popup ref={menu} />
      <div className="breadcrumb-wrapper border-top-1 border-500 bg-white px-4 py-3">
        <p className="mt-0 mb-1 text-lg font-medium text-color">{breadcrumbs[breadcrumbs.length-1]}</p>
        <BreadCrumb
          className="bg-white p-0 text-color-secondary"
          model={breadcrumbs.map((e) => {
            return { label: e };
          })}
          home={{ icon:"pi pi-home", url: "/" }}
        />
      </div>
      {children}
      <div className="static bottom-0 left-0 p-3 w-full surface-600">
          <p className="mb-0 mt-0 text-xs">
          © {(new Date().getFullYear())} – Observing Practice Quality Tool (OPQ) was designed, developed, and tested by the Living with Disability Research Centre, La Trobe University. The app and technology solution was developed by
          the Centre for Data Analytics and Cognition (CDAC) at La Trobe University.
          </p>
      </div>
    </div>
  );
}
