import FullScreenLayout from "../layouts/full-screen-layout";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import image from "../resources/forgot-password.svg";
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import AccountService from "../services/AccountService";
import { useNavigate } from "react-router-dom";
import logo from "../resources/logo.svg";

export default function ForgotPassword() {
  const redirect = useNavigate();
  const toast = useRef(null);
  const [phone, setPhone] = useState("");

  const onPressEnter = (e) => {
    if (e.key === "Enter") {
      onClick();
    }
  };

  const onClick = () =>
    AccountService.forgotPassword(phone)
      .then((result) => {
        if (result) {
          toast.current.show({ severity: "success", detail: "OTP has sent" });
          redirect(`/validate-mfa/${phone}`);
        }
      })
      .catch((e) => console.error(e));

  return (
    <FullScreenLayout>
      <Toast ref={toast}></Toast>
      {/* <> */}
        <div className="flex-auto mt-8 shadow-none border-noround w-26rem bg-white px-4 py-4 border-1 border-300">
          <div>
            <div className="flex flex-column align-items-center justify-content-center border-round m-2">
              <img src={logo} alt="latrobe-logo" width={56} className="flex justify-content-center ml-5 mr-5" />
              <p className="text-color font-bold">Forgot Password?</p>
            </div>
            <div className="field mb-4">
              <label htmlFor="phoneNo">Phone number</label>
              <InputText className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" id="phoneNo" type="text" onKeyUpCapture={onPressEnter} placeholder="Enter phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </div>
          </div>
          <div>
            <Button className="w-full" label="Send" disabled={phone.trim() === ""} onKeyUpCapture={onPressEnter} onClick={onClick} />
            <Link to="/login" className="flex text-blue-500 no-underline justify-content-center w-full mt-4">
              Return to log in
            </Link>
          </div>
        </div>
        {/* </> */}
      
    </FullScreenLayout>
  );
}
