export function setSelf(user) {
  localStorage.setItem("user", btoa(JSON.stringify(user)));
}

export function getSelf() {
  let expire = parseInt(localStorage.getItem("expire"));
  if (isNaN(expire) || new Date().getTime() / 1000 > expire) {
    return null;   
  }
  return JSON.parse(atob(localStorage.getItem("user")));
}


export function  hasManagerOrSuperAdminRole(user) {
  if (user && Array.isArray(user.roles)) {
    return user.roles.includes("Manager") || user.roles.includes("SuperAdministrator")||user.roles.includes("TenantAdministrator");
  }
  return false;
}
export function  hasDeleteAccess(user) {
  if (user && Array.isArray(user.roles)) {
    return user.roles.includes("Manager") || user.roles.includes("TenantAdministrator");
  }
  return false;
}


export function clean() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("type");
  localStorage.removeItem("expire");
}

export function updateTokens(accessToken, refreshToken, type, expire) {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("type", type);
  localStorage.setItem("expire", expire);
}

export function getAccessToken() {
  let expire = parseInt(localStorage.getItem("expire"));
  if (isNaN(expire) || new Date().getTime() / 1000 > expire) {
    clean();
    return null;
  }
  return localStorage.getItem("accessToken");
}

export function getRefreshToken() {
  let expire = parseInt(localStorage.getItem("expire"));
  if (isNaN(expire) || new Date().getTime() / 1000 > expire) {
    clean();
    return null;
  }
  return localStorage.getItem("refreshToken");
}
