import FullScreenLayout from "../layouts/full-screen-layout";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import image from "../resources/mfa.svg";
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import AccountService from "../services/AccountService";
import { useNavigate, useParams } from "react-router-dom";

export default function ForgotPassword() {
  const redirect = useNavigate();
  const toast = useRef(null);
  const [token, setToken] = useState(null);
  const [otp, setOtp] = useState("");
  const [id, setId] = useState(null);
  const phone = useParams().phoneNo;

  const onClick = () =>
    AccountService.forgotPasswordValidate(phone, otp)
      .then(({ id, token }) => {
        setToken(token);
        setId(id);
      })
      .then(() => toast.current.show({ severity: "success", detail: "Verification success" }))
      .catch((e) => console.error(e));

  const onPressEnter = (e) => {
    if (e.key === "Enter" && token != null) {
      onClick();
    }
  };

  return (
    <FullScreenLayout>
      <Toast ref={toast}></Toast>
      <Card>
        <div className="flex flex-column align-items-center justify-content-center border-round m-2">
          <img src={image} alt="latrobe-logo" width={200} className="flex justify-content-center" />
          <h2>Enter OTP</h2>
        </div>
        <div className="field">
          <label htmlFor="phoneNo">Verification code</label>
          <div className="p-inputgroup">
            <InputText id="otp" type="text" placeholder="Enter verification code" value={otp} onChange={(e) => setOtp(e.target.value)} onKeyUpCapture={onPressEnter} />
            <Button label="Verify" disabled={otp.trim() === ""} className="p-button-success" onClick={onClick} onKeyUpCapture={onPressEnter} />
          </div>
        </div>
        <Button disabled={token == null} label="Reset password" className="w-full" onClick={() => redirect(`/reset-password/${btoa(JSON.stringify({ id, token })).replaceAll("/", "%2f")}`)} />
        <Divider />
        <Link to="/login" className="flex text-blue-500 no-underline justify-content-center w-full">
          Return to log in
        </Link>
      </Card>
    </FullScreenLayout>
  );
}
