import axios from "./axios";
export default class AccountService {
  static forgotPassword(phoneNumber) {
    return axios.post("Account/forgotpassword", { phoneNumber }).then((response) => response.data);
  }

  static forgotPasswordValidate(phoneNumber, token) {
    return axios.post("Account/forgotpasswordvalidate", { phoneNumber, token }).then((response) => response.data);
  }

  static resetPassword(id, token, password, confirmPassword) {
    return axios.post("Account/resetpassword", { id, token, password, confirmPassword }).then((response) => response.data);
  }

  static changePassword(password, newPassword, confirmNewPassword) {
    return axios.post("Account/changePassword", { password, newPassword, confirmNewPassword }).then((response) => response.data);
  }

  static userInfo() {
    return axios.get("Account").then((response) => response.data);
  }
}
