import axios from "./axios";
import { updateTokens, clean, getAccessToken, getRefreshToken } from "../util/cache";

export default class AuthenticationService {
  static login(email, password, rememberMe) {
    return axios.post("Auth/LogIn", { email, password, rememberMe }).then(({ data }) => {
      updateTokens(data.token, data.refreshToken, data.tokenType, JSON.parse(atob(data.token.split(".")[1])).exp);
    });
  }

  static logout() {
    return axios.post("Auth/LogOut", { refreshToken: getRefreshToken() }).then(() => clean());
  }

  static refresh() {
    let token = getAccessToken();
    let refreshToken = getRefreshToken();
    return axios.post("Auth/Refresh", { token, refreshToken }).then(({ data }) => {
      updateTokens(data.token, data.refreshToken, data.tokenType, JSON.parse(atob(data.token.split(".")[1])).exp);
    });
  }
}
