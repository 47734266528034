import { useEffect, useState } from "react";
import Layout from "../layouts/main-layout";
import AccountService from "../services/AccountService";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { ListBox } from "primereact/listbox";

export default function UserInfo() {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    email: "",
    phoneNumber: "",
    roles: [],
  });

  useEffect(() => {
    AccountService.userInfo().then((user) => setUser(user));
  }, []);
  return (
    <Layout breadcrumbs={["User Information"]}>
      <div className="grid py-4 px-4 surface-500 flex-grow-1 m-0 justify-content-center">
        <div className="xl:col-5 lg:col-6 col-12">
          <Card>
          <h2 className="mt-0 mb-5 text-center text-color">User Information</h2>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 ">
              <label htmlFor="firstName">First name</label>
              <InputText id="firstName" value={user.firstName} disabled />
            </div>
            <div className="field col-12 ">
              <label htmlFor="lastName">Last name</label>
              <InputText id="lastName" value={user.lastName} disabled />
            </div>
            <div className="field col-12 ">
              <label htmlFor="organization">Organization</label>
              <InputText id="organization" value={user.organization} disabled />
            </div>
            <div className="field col-12 ">
              <label htmlFor="email">Email</label>
              <InputText id="email" value={user.email} disabled />
            </div>
            <div className="field col-12 ">
              <label htmlFor="phoneNumber">Phone Number</label>
              <InputText id="phoneNumber" value={user.phoneNumber} disabled />
            </div>
          </div>
          </Card>
        </div>
      </div>
      
    </Layout>
  );
}
