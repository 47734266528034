import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { useEffect, useState } from "react";
import { Card } from "primereact/card";
import Layout from "../layouts/main-layout";
import ObservationService from "../services/ObservationService";
import ResponseService from "../services/ResponseService";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { getSelf, setSelf, getAccessToken, hasManagerOrSuperAdminRole } from "./../util/cache";

export default function Dashboard() {
  const [recentObservations, setRecentObservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRoleReturn] = useState(false);
  const [auth, setAuth] = useState([])
  const [stats, setStats] = useState({
    totalUserResponses: 0,
    totalOrgResponses: 0,
    yourAverage: 0,
    organizationAverage: 0,
  });

  useEffect(() => {
    setLoading(true);
  

    const UserData = getSelf();
    const Access = hasManagerOrSuperAdminRole(UserData);
    setRoleReturn(Access)



    ResponseService.getRecentObservations().then((observations) => {
    //  console.log(observations, "observations")
      let data = observations.data.map((e) => {
        return {
          createdDate: format(new Date(e.createdDate), "dd/MM/yyyy"),
          startTime: format(new Date(e.startTime), "hh:mm:ss aa"),
          finishTime: format(new Date(e.createdDate), "hh:mm:ss aa"),
          organization: e.serviceOrganization,
          address: e.address,
          staffMember: e.staffMemberObserved,
          supported: e.personSupportedObserved,
          score: parseFloat(e.totalScore || "0"),
        };
      });
      setRecentObservations(data);
    }).finally(() => setLoading(false));
    ObservationService.getStats().then((stats) => setStats(stats));
  }, []);


  const renderHeader = (
    <><div className="flex overflow-hidden text-sm">
      <span className="flex-none flex align-items-center justify-content-center">Saved observations</span>
      <div className="flex-grow-1 flex align-items-center justify-content-center"></div>
      <Link to="/observations" className="no-underline flex-none flex align-items-center justify-content-center text-blue-500">
        View All <i className="pi pi-angle-right" />
      </Link>
    </div><Divider /></>
  );

  return (
    <Layout breadcrumbs={["Dashboard"]}>
      <div className="grid py-4 px-4 surface-500 flex-grow-1 m-0">
        <div className="xl:col-7 lg:col-7 col-12">
          <Card title={renderHeader} className="card-dataTable">
            <DataTable stripedRows value={recentObservations} loading={loading} responsiveLayout="scroll">
              <Column field="createdDate" header="Date" />
              <Column field="address" header="Address" />
              <Column field="staffMember" header="Worker" />
              <Column field="supported" header="Person supported" />
              <Column field="score" header="Score" />
              <Column field="startTime" header="Start time" />
              <Column field="finishTime" header="Finish time" />
              <Column field="organization" header="Organisation" />
            </DataTable>
          </Card>
        </div>
        <div className="xl:col-5 lg:col-5 col-12">
          <div className="grid">
            <div className="lg:col-12 xl:col-6 md:col-6 col-12 px-3">
              <Card className="card-data">
                <div>
                  <span className="text-5xl text-blue-700 font-bold">{stats.totalUserResponses}</span>
                  <p className="text-sm my-0">Your total observations</p>
                </div>
              </Card>
            </div>
            {role ? (
            <div className="lg:col-12 xl:col-6 md:col-6 col-12 px-3">
              <Card className="card-data">
                <div>
                  <span className="text-5xl text-green-700 font-bold">{stats.totalOrgResponses}</span>
                  <p className="text-sm my-0">Total observations of the organisation</p>
                </div>
              </Card>
            </div>
             ) : null}
            <div className="lg:col-12 xl:col-6 md:col-6 col-12 px-3">
              <Card className="card-data">
                <div>
                  <span className="text-5xl text-blue-700 font-bold">{stats.yourAverage}</span>
                  <p className="text-sm my-0">Your average score</p>
                </div>
              </Card>
            </div>
            {role ? (
              <div className="lg:col-12 xl:col-6 md:col-6 col-12 px-3">
                <Card className="card-data">
                  <div>
                    <span className="text-5xl text-green-700 font-bold">{stats.organizationAverage}</span>
                    <p className="text-sm my-0">Your organisation’s average score</p>
                  </div>
                </Card>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
}
