export default function FullScreenLayout({ children }) {
  return (
    <div className="flex flex-wrap card-container min-h-screen surface-500 align-items-start justify-content-center">
      <div className="flex border-round m-2">{children}</div>
      <div className="align-self-end p-3 w-full surface-600">
          <p className="mb-0 mt-0 text-xs">
            © {(new Date().getFullYear())} – Observing Practice Quality (OPQ) was designed, developed, and tested by the Living with Disability Research Centre, La Trobe University. The technology solution was developed by
            the Centre for Data Analytics and Cognition (CDAC) at La Trobe University.
          </p>
      </div>
    </div>
  );
}
