import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import logo from "../resources/logo.svg";
import FullScreenLayout from "../layouts/full-screen-layout";
import React, { useState, useRef } from "react";
import AuthenticationService from "../services/AuthenticationService";
import AccountService from "../services/AccountService";
import { Toast } from "primereact/toast";
import { useNavigate, Link } from "react-router-dom";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { setSelf } from "../util/cache";

export default function Login() {
  const redirect = useNavigate();
  const toast = useRef(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClickLogin = () => {
    setLoading(true);
    AuthenticationService.login(username, password, rememberMe)
      .then(() => AccountService.userInfo())
      .then((user) => setSelf(user))
      .then(() => redirect("/"))
      .catch((e) => {console.log(e,"login error")
        toast.current.show({ severity: "error", summary: "Authentication Failed", detail:e.message==="Network Error"?e.message: e.response.data.Errors[0], life: 3000 })})
      .finally(()=>setLoading(false));
  };

  const onPressEnter = (e) => {
    if (e.key === "Enter") {
      onClickLogin();
    }
  };

  return (
    <FullScreenLayout>
      <Toast ref={toast}></Toast>
      <div className="flex-auto mt-8 shadow-none border-noround w-26rem bg-white px-4 py-4 border-1 border-300">
        <div className="flex flex-column align-items-center justify-content-center border-round m-2">
          <img src={logo} alt="latrobe-logo" width={56} className="flex justify-content-center ml-5 mr-5" />
          <p className="text-color font-bold">Welcome to OPQ Tool</p>
        </div>
        <div className="field">
          <label htmlFor="username">Email</label>
          <InputText
            id="username"
            type="text"
            placeholder="type user"
            className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="field mb-4">
          <label htmlFor="password">Password</label>
          <InputText
            id="password"
            type="password"
            className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            value={password}
            placeholder="type password"
            onKeyUpCapture={onPressEnter}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="flex flex-row justify-content-between mb-5">
          <div className="field-checkbox">
            <Checkbox id="rememberme" checked={rememberMe} onChange={(e) => setRememberMe(e.checked)} />
            <label htmlFor="rememberme">Remember me</label>
          </div>
          <Link to="/forgot-password" className="no-underline text-sm text-primary flex justify-content-center cursor-pointer">
            Forgot your password?
          </Link>
        </div>
        
        <Button disabled={username.trim() === "" || password.trim() === ""} label="Sign In" className="w-full" onClick={onClickLogin} loading={loading} />
        
      </div>
    </FullScreenLayout>
  );
}
