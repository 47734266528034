import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import Observations from "./views/Observations";
import UserInfo from "./views/UserInfo";
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import ResetPassword from "./views/ResetPassword";
import ValidateMFA from "./views/ValidateMFA";
import AuthenticationService from "./services/AuthenticationService";
import AccountService from "./services/AccountService";
import { Navigate, Outlet } from "react-router-dom";
import { getSelf, setSelf, getAccessToken } from "./util/cache";

function App() {
  const ticker = () => {
    if (getAccessToken() != null) {
      AuthenticationService.refresh()
        .then(() => AccountService.userInfo())
        .then((user) => setSelf(user));
    }
  };

  ticker();
  setInterval(ticker, 300000);

  const ProtectedRoute = ({ redirectPath = "/login", children }) => {
    if (getSelf() == null) {
      return <Navigate to={redirectPath} replace />;
    }
    return children ? children : <Outlet />;
  };
//auth
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route index element={<Dashboard />} />
          <Route path="observations" element={<Observations />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="user-info" element={<UserInfo />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="validate-mfa/:phoneNo" element={<ValidateMFA />} />
        <Route path="reset-password/:payload" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
}

export default App;
