import axios from "axios";

let _axios = axios.create({baseURL: "https://webapi.observingstaffsupport.com.au/api"});

_axios.interceptors.request.use(
  (config) => {
    let authToken = localStorage.getItem("accessToken");
    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default _axios;
