import { useState, useRef } from "react";
import Layout from "../layouts/main-layout";
import AccountService from "../services/AccountService";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { isValid } from "date-fns";

export default function ChangePassword() {
  const toast = useRef(null);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordValidationMessage, setPasswordValidationMessage] = useState("");
  const [passwordConfirmationMessage, setPasswordConfirmationMessage] = useState("");

  const isPasswordValid = () => {
    // Define a regular expression for the password validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValid = passwordRegex.test(newPassword);
    setPasswordValidationMessage(isValid ? "" : "Password must contain at least 8 characters with a minimum of one upper case, one lower case, and a number");
    return isValid;
  };
  //cofirm pass bool
  const confirmPass= confirmNewPassword===newPassword;
  


  


  return (
    <Layout breadcrumbs={["Account", "Change Password"]}>
      <div className="grid py-4 px-4 surface-500 flex-grow-1 m-0 justify-content-center">
        <div className="xl:col-5 lg:col-6 col-12">
          <Toast ref={toast}></Toast>
          <div className="flex justify-content-around">
            <Card>
              <h2 className="mt-0 mb-5 text-center text-color">Change Password</h2>
              <div className="p-fluid grid formgrid">
                <div className="field col-12 ">
                  <label htmlFor="password">Current Password</label>
                  <InputText id="password" type="password" value={password} placeholder="Current password" onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="field col-12 ">
                  <label htmlFor="newPassword">New Password</label>
                  <InputText id="newPassword" type="password" value={newPassword} placeholder="New password" onChange={(e) => {
                    setNewPassword(e.target.value)
                    if (!isPasswordValid()) {
                      // /  toast.current.show({ severity: "error", detail: "Please correct the password error" });
                    }

                  }
                  } />
                  {passwordValidationMessage && <p style={{color:"red"}}>{passwordValidationMessage}</p>}
                </div>
                <div className="field col-12 ">
                  <label htmlFor="confirmNewPassword">Confirm New Password</label>
                  <InputText id="confirmNewPassword" type="password" value={confirmNewPassword} placeholder="Re-type new password" onChange={(e) => {
                    setConfirmNewPassword(e.target.value)
                   }} />
                     {!confirmPass && <p style={{color:"red"}}>confirm password does not match.</p>}

                </div>
              </div>
              <Button
                label="Update Password"
                onClick={() => {
                 
                  if (!isPasswordValid() || !confirmPass) {
                       toast.current.show({ severity: "error", detail: "Please correct the password error" });
                  } else {
                    AccountService.changePassword(password, newPassword, confirmNewPassword)
                    .then(() =>{ 
                      toast.current.show({ severity: "success", detail: "Password updated" })
                      setNewPassword("")
                      setConfirmNewPassword("")
                      setPassword("")
                    
                    })
                      .catch((e) => toast.current.show({ severity: "error", summary: e, detail: "Unable to change password" }));
                  }
                }}
              />
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
}
 