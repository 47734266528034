import axios from "./axios";

export default class ValueService {
  static getStaff() {
    return axios.get("Values/Staff").then((response) => response.data);
  }

  static getObservers() {
    return axios.get("Values/Observers").then((response) => response.data);
  }

  static getLocations() {
    return axios.get("Values/Locations").then((response) => response.data);
  }

  static getResponseMonths() {
    return axios.get("Values/ResponseMonths").then((response) => response.data);
  }
  static getPersonSupported() {
    return axios.get("Values/personSupported").then((response) => response.data);
  }
}
