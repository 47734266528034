import FullScreenLayout from "../layouts/full-screen-layout";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import image from "../resources/reset-password.svg";
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import AccountService from "../services/AccountService";
import { useNavigate, useParams } from "react-router-dom";

export default function ResetPassword() {
  const redirect = useNavigate();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const params = JSON.parse(atob(useParams().payload.replaceAll("%2f", "/")));

  const isValidPassword = (password) => {
    // Password must contain at least 8 characters, one uppercase, one lowercase, and one number
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordPattern.test(password);
  };

  const handlePasswordChange = (newPassword) => {
    setPassword(newPassword);
    if (newPassword.trim() === "") {
      setPasswordError("Password is required!");
    } else if (!isValidPassword(newPassword)) {
      setPasswordError("Password must contain at least 8 characters\nwith a minimum of one upper case,\none lower case, \n and a number.");
    } else {
      setPasswordError("");
    }
  };

  const onClick = () => {
    if (!isValidPassword(password)) {
      setPasswordError("Password must meet the criteria");
    } else if (password !== password2) {
      setPasswordError("Passwords do not match");
    } else {
      AccountService.resetPassword(params.id, params.token, password, password2)
        .then(() => {
          redirect("/login");
        })
        .catch((e) => console.error(e));
    }
  };

  const onPressEnter = (e) => {
    if (e.key === "Enter" && !(password.trim() === "" || password2.trim() === "" || password !== password2)) {
      onClick();
    }
  };

  return (
    <FullScreenLayout>
      <Card>
        <div className="flex flex-column align-items-center justify-content-center border-round m-2">
          <img src={image} alt="latrobe-logo" width={300} className="flex justify-content-center" />
          <h2>Recover account</h2>
        </div>
        <div className="field">
          <label htmlFor="password">Password</label>
          <InputText
            id="password"
            type="password"
            className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            value={password}
            placeholder="New password"
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          {passwordError && <p className="text-red-500"style={{color:"red"}}>Password must contain at least 8 characters with<br/> a minimum of one upper case,none lower case, <br/> and a number.</p>}
        </div>
        <div className="field">
          <label htmlFor="password2">Confirm Password</label>
          <InputText
            id="password2"
            type="password"
            className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus.border-primary w-full"
            value={password2}
            placeholder="Confirm new password"
            onChange={(e) => setPassword2(e.target.value)}
            onKeyUpCapture={onPressEnter}
          />
        </div>
        <Button
          disabled={!isValidPassword(password) || password.trim() === "" || password2.trim() === "" || password !== password2}
          label="Reset Password"
          className="w-full"
          onClick={onClick}
        />
        <Divider />
        <Link to="/login" className="flex justify-content-center no-underline text-blue-500 w-full">
          Return to log in
        </Link>
      </Card>
    </FullScreenLayout>
  );
}
